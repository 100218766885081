<template>
<div>
    <div class="col-md-12">
        <div class="row" style="margin-top: 20px">
            <div class="col-md-6">
            <h3 class="page-header">
                <i class="fa fa fa-calculator animated bounceInDown show-info"></i>
                <span>Over Dues Emi Report</span>
            </h3>
            </div>
            <div class="col-md-6 text-right">
            <button @click="refresh()" class="btn btn-refresh">
                <i class="fa fa-refresh"></i>
            </button>
            </div>
        </div>
    </div>
    <div v-if="loginusercheck(160)" class="boxshow">
        <div class="info-box bg-warning text-white">
            <div class="info-icon bg-primary-dark" style="padding-bottom:5px;"> 
                <h5>
                    <i class="fa fa-check-square-o"></i>
                    Select REC BY
                </h5>
            </div>
        </div>
        <div class="col-md-12 ">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-3" v-for="(item) in employees" v-bind:key="item.id">
                        <!-- <usercheck :empid="item.id"></usercheck>  {{item.name}} -->
                        <input type="checkbox" 
                        :value="item.id"     
                        id="item.id" 
                        v-model="checkedCategories" 
                        @change="check($event)">{{item.name}}
                    </div>
                </div>
            </div>
        </div>
    </div>
        
    <div class="col-md-12 mt-20 pb-10 boxshow">
        <div class="flex-between-row">
            <div class="col-md-3 flex-between-row">
                <div class="">
                    <strong>Show Rec.</strong>
                </div>
                <div>
                    <select
                    class="form-control col-mb-1"
                    v-model="noofrec"
                    id="rec"
                    @change="refresh()"
                    >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    </select>
                </div>
            </div>
            <div class="">
                <label for="">Total Rec. :</label>
                <span :class="danger==true?'badge bg-danger':'badge bg-seagreen'">
                    <i class="fa fa-inr"></i>{{ overduescount }}
                </span>
            </div>
            <div class="flex-between-row">
                <label for="">Search Lan</label>
                <input type="text"  class="" v-model="loanaccount">
                <button class="btn btn-success" @click="refresh()"><i class='bx bx-search-alt-2'></i></button>
            </div>
            
        </div>
        
    </div>
    <div class="pt-10">
        <div class="table-responsive panel-body">
            <table class="table users-table table-condensed table-hover table-sm table-bordered">
                <thead class="table-font">
                <tr>
                    <th>#</th>
                    <th>LAN No.</th>
                    <th>Customer Name</th>
                    <!-- <th>Zone</th> -->
                    <th>Address</th>
                    <th>Mobiles</th>
                    <th>Emi Amt</th>
                    <th>Rem. Amt</th>
                    <th>Emi Date</th>
                    <th>Due Date</th>
                    <th>Day of Delay</th>
                    <th>Fallow By</th>
                    <th>Conversation</th>
                </tr>
            </thead>
                <emi-over-due-row v-for="(item,index) in overdues" :key="item.id" 
                :item="item" :index="index" :currentpage="currentpage" :noofrec="noofrec" ></emi-over-due-row>
            </table>
        </div>
        <div class="col-md-12" style="margin-top: 20px">
            <nav aria-label="Page navigation example" style="text-align: right !important">
            <paginate
                style="margin: 0px !important; float: right"
                :page-count="noofpages"
                :click-handler="clickCallback"
                :prev-text="'&laquo;'"
                :next-text="'&raquo;'"
                :container-class="'pagination'"
            >
            </paginate>
            </nav>
        </div>
    </div>
</div>
</template>
<script>
import EmiOverDueRow from "./EmiOverDueRow.vue";
import Paginate from "vuejs-paginate";
import { mapGetters } from "vuex";
import Constants from "../../components/utilities/Constants.vue";
export default {
    mixins:[Constants],
    components:{EmiOverDueRow,Paginate},
    data() {
        return {
            noofrec:10,
            overdues:[],
            currentpage:1,
            overduescount:0,
            loanaccount:'',
            checkedCategories:[],
            emidatecount:0,
            danger:false
        }
    },
    mounted() {
        this.$store.dispatch('fetchemployees')
        this.$store.dispatch('getloggedinuser');
        this.refresh();
    },
    computed: {
        ...mapGetters(["edititem", "viewno", "scheduleloanemicount", "scheduleloanemi",
            "employees","attributes","accountstatus","loanaccounttype","sumofremainingamount","loggedinuser"]),
        noofpages() {
            return Math.ceil(parseInt(this.overduescount) / parseInt(this.noofrec));
        },
    },
    methods: {
        clickCallback(currentpage) {
            this.currentpage = currentpage;
            this.$store.commit("assigncurrentpage", currentpage);
            this.refresh();
        },
        check (e) {
            this.$nextTick(() => {
                console.log(this.checkedCategories, e)
            })
            this.refresh()
        },
        refresh(){
            this.$store.commit('assignloadingstatus',1)
            setTimeout(() => {
                if(!this.loginusercheck(160)){
                    console.log("entered in getloggedin")
                    console.log(this.loggedinuser?.id)
                    if(this.checkedCategories.length>0){
                        this.checkedCategories.forEach(element => {
                            if(!element==this.loggedinuser.id){
                                this.checkedCategories.push(this.loggedinuser?.id)
                            }
                        });
                    }else{
                        this.checkedCategories.push(this.loggedinuser?.id)
                    }
                    console.log(this.checkedCategories)
                }
                
                let param={noofrec:this.noofrec,currentpage:this.currentpage,loanaccount:this.loanaccount,
                empid:this.checkedCategories}
                window.axios.post('api/emi/overdue/accounts',param)
                    .then((response) => this.processTotaltResponse(response.data))
                    .catch((err) => {
                    console.log(err)
                });
            }, 2000);
        },
        processTotaltResponse(data){
            this.$store.commit('assignloadingstatus',0)
            this.overdues=data.overdues
            this.overduescount=data.count
            this.emidatecount=data.emidatecount
            let percent =0
            if(this.overduescount!=0){
                percent=(parseInt(this.overduescount) * 10)/100;
                if(this.emidatecount > percent){
                    this.danger=true
                }else{
                    this.danger=false
                }
            }
        },
        

    },
}
</script>