<template>
    <tbody class="table-font">
        <tr >
            <td>{{ (currentpage - 1) * noofrec + (index + 1) }} </td>
            <!-- <td>
                <span v-if="item.loanemi!=null && item.loanemi.loanaccount!=null">{{item.loanemi.loanaccount.manualid}}</span>
            </td> -->
            <td>
                <a
                    v-if="item.loanaccount != null"
                    href="#"
                    @click="loandetails(item.loanaccount.id)">
                    <div v-if="item != null && item.loanaccount != null">
                    <span style="font-size: 13px">
                        {{ item.loanaccount.manualid }}</span>
                    </div>
                </a>
            </td>
            <td>
                <span
                    v-if="
                    item.loanaccount != null &&
                    item.loanaccount.customer != null
                    "
                >
                    {{ item.loanaccount.customer.name }}</span
                >
            </td>
            <td>
                <display-first-address
                    v-if="item.loanaccount.customer != null"
                    :addresses="item.loanaccount.customer.addressess"
                ></display-first-address>
            </td>
            <td>
            <display-mobiles
                    v-if="item.loanaccount.customer != null"
                    :mobiles="item.loanaccount.customer.mobiles"
                ></display-mobiles>
            </td>
            <td>
                <span><i class="bx bx-rupee"></i> {{ item.tobepaid }}</span>
            </td>
            <td>
                <span
                    v-if="item.loanaccount != null"
                    style="color:red;font-weight:700"
                >
                    {{ item.loanaccount.remainingamount }}
                </span>
            </td>
            <td>
                <span
                    ><i class="bx bxs-calendar"></i>
                    {{ moment(item.emidate).format("DD-MM-YYYY") }}</span
                >
            </td>
            <td>
                <span v-if="item.scheduled!=null" 
                    ><i class="bx bxs-calendar"></i>
                    <!-- <span style="color:red">Over-Dated </span><br> -->
                    {{ moment(item.scheduled.scheduleddate).format("DD-MM-YYYY") }}</span
                >
            </td>
            <td class="text-center">
                <button :class="danger==true?'badge bg-danger':'badge bg-seagreen'">
                    <i class="bx bx-calendar"></i> {{ diffdates(item) }}
                </button>
            </td>
            <td>
                <div
                    v-if="
                    item.loanaccount != null &&
                    item.loanaccount.loanfollowed != null">
                    {{ item.loanaccount.loanfollowed.name }}
                </div>
            </td>
            <!-- <td>
                <div class="form-group">
                    <select class="form-control" v-model="accounttype" @change="accountchange(item)">
                        <option :value="0">Due Acc.</option>
                        <option :value="1">Clear Acc</option>
                        <option :value="2">Deafulter Acc</option>
                        <option :value="3">Legal Acc</option>
                        <option :value="4">Concession Acc</option>
                    </select>
                </div>
            </td> -->
            <td>
                <button class="btn btnsearch btn-view emi-detail-btn" type="button" @click="visitcomment()">
                    <i v-if="visitcomments==false" class="fa fa-plus"></i>
                    <i  v-else class="fa fa-minus"></i>  View details
                </button>
            </td>
        </tr>
        <tr v-if="visitcomments">
            <td colspan="12">
                <loan-emi-visit-comment :emiid="item.id" :followed="item.loanaccount?.followed" :emi="item" :commentlist="commentlist"></loan-emi-visit-comment>
            </td>
        </tr>
    </tbody>
    
</template>
<script>
import Constants from "../../components/utilities/Constants.vue";
import moment from "moment";
import LoanEmiVisitComment from "../../components/loanemi/LoanEmiVisitComment.vue";
import { mapGetters } from "vuex";
import DisplayFirstAddress from "../../components/address/DisplayFirstAddress.vue";
import DisplayMobiles from "../../components/mobile/DisplayMobiles.vue";
export default {
    mixins:[Constants],
    components:{LoanEmiVisitComment ,DisplayFirstAddress ,DisplayMobiles},    
    props:['item','index','currentpage','noofrec'],
    data() {
        return {
            visitcomments:false,
            moment:moment,
            accounttype:0,
            commentlist:[],
            danger:false
        }
    },
    computed: {
        ...mapGetters(["attributes",'viewno'])
    },
    
    methods: {
        getcomment(id){
            if(this.comments!=null){
                return this.comments.find(a=>{
                    if(a.emiid==id){
                        return a
                    }
                })
            }
        },
        
        accountchange(item){
            console.log(this.accounttype)
            this.$store.commit("assignaccountstatus",item)
            this.$store.commit("assignloanaccounttype",this.accounttype)
            this.$modal.show('loanaccount');
        },
        diffdates(item) {
            const currentdate = moment().toDate("YYYY-MM-DD");
            const diffInDays = moment(currentdate).diff(moment(item.emidate), "days");
            if (diffInDays > 15) {
                this.danger=true
                return diffInDays;
            } else if(diffInDays <= 15) {
                this.danger=false
                return diffInDays;
            }else{
                this.danger=false
                return 0;
            }
            
        },
        bucket(item) {
            const currentdate = moment().toDate("YYYY-MM-DD");
            const diffInDays = moment(currentdate).diff(moment(item.emidate), "months");
            if (diffInDays < 0) {
                return 0;
            } else {
                return diffInDays;
            }
        },
        loandetails(id) {
            let param = { id: id };
            this.$http.post("api/loan/details", param)
                .then((response) => this.processloanResponse(response.data))
                .catch((err) => {
                console.log(err);
                });
        },
        processloanResponse(data) {
            this.$store.commit("assignedititem", data);
            this.$store.commit("assignviewno", 2);
        },
        visitcomment(){
            let param = {emiid:this.item.id}
            this.$http.post('api/loan/emi/visitcomments/fetch',param)
            .then((response) => this.processDetailResponse(response.data))
            .catch((err) => {
                console.log(err)
            });
            this.visitcomments = !this.visitcomments;
        },
        processDetailResponse(data){
            this.$store.commit('assignlist',data)
            this.commentlist=data
        },
        refresh(){
            this.$parent.refresh();
        },
        

    },
}
</script>
<style>
</style>